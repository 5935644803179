import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { nightOwl, github, oceanicNext, vsDark } from "@code-surfer/themes";
import MyComponent from "../../../../decks/full/my-component.js";
import customTheme from "../../../../decks/full/custom-theme.js";
import "prismjs/components/prism-smalltalk";
import React from 'react';
export default {
  CodeSurfer,
  CodeSurferColumns,
  Step,
  nightOwl,
  github,
  oceanicNext,
  vsDark,
  MyComponent,
  customTheme,
  React
};