import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { nightOwl, dracula, oceanicNext, vsDark } from "@code-surfer/themes";
import { script } from "mdx-deck/themes";
import React from 'react';
export default {
  CodeSurfer,
  CodeSurferColumns,
  Step,
  nightOwl,
  dracula,
  oceanicNext,
  vsDark,
  script,
  React
};