import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { nightOwl, dracula, oceanicNext, vsDark } from "@code-surfer/themes";
import ImageComponent from "../../../../decks/demo/image";
import logo from "../../../../decks/demo/logo.small.svg";
import { Head } from "mdx-deck";
import theTheme from "../../../../decks/demo/theme.js";
import React from 'react';
export default {
  CodeSurfer,
  CodeSurferColumns,
  Step,
  nightOwl,
  dracula,
  oceanicNext,
  vsDark,
  ImageComponent,
  logo,
  Head,
  theTheme,
  React
};