import { CodeSurfer } from "code-surfer";
import { dracula, duotoneDark, duotoneLight, github, nightOwl, oceanicNext, shadesOfPurple, ultramin, vsDark } from "@code-surfer/themes";
import customTheme from "../../../../decks/custom-theme";
import React from 'react';
export default {
  CodeSurfer,
  dracula,
  duotoneDark,
  duotoneLight,
  github,
  nightOwl,
  oceanicNext,
  shadesOfPurple,
  ultramin,
  vsDark,
  customTheme,
  React
};